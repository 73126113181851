import CGWhiteIcon from "../../assets/cg-white-icon.svg";

export default function Footer() {
  return (
    <footer className="bg-black text-white py-6 px-10">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between">
          <div className="lg:flex items-center gap-6">
            <p>© CG MERCHANTS 2022</p>
            <a
              href="mailto:operations@cgmerchants.com"
              target="_blank"
              rel="noreferrer"
            >
              operations@cgmerchants.com
            </a>
          </div>
          <img src={CGWhiteIcon} className="w-6" />
        </div>
      </div>
    </footer>
  );
}
