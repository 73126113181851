import React from "react";
import teamImage from "../../assets/team.svg";

const Team = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} id="team" className="max-w-7xl mx-auto pt-20 lg:h-700">
      <div className="grid grid-cols-2 gap-4">
        <img className="col-span-2 sm:col-span-1" src={teamImage} />
        <div className="col-span-2 sm:col-span-1 p-20">
          <h3 className="text-ceibos text-46 pb-20">TEAM</h3>
          <p className="lg:text-xl font-light">
            Formed by a small but experienced commercial and operational team,
            we are committed to being the ideal trading partner for both buyers
            and sellers, understanding customer needs and sourcing products to
            suit them from reliable producers and exporters.
          </p>
        </div>
      </div>
    </div>
  );
});

Team.displayName = "Team";

export default Team;
