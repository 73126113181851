import React from "react";
import { LocationMarkerIcon, MailIcon } from "@heroicons/react/outline";

const Contact = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} id="contact" className="bg-ceibos h-420 w-full">
      <div className="max-w-7xl mx-auto pt-20">
        <h3 className="text-center text-white font-bold text-32">
          CONTACT INFORMATION
        </h3>
        <div className="text-center flex flex-col mt-8">
          <LocationMarkerIcon className="h-6 w-6 text-ceibos-pink m-auto" />
          <p className="text-lg text-white">
            25 de Mayo 741 – 8th Floor – Montevideo, Uruguay
          </p>
        </div>
        <div className="text-center flex flex-col mt-8">
          <MailIcon className="h-6 w-6 text-ceibos-pink m-auto" />
          <a
            href="mailto:operations@cgmerchants.com"
            target="_blank"
            rel="noreferrer"
            className="text-lg text-white font-bold"
          >
            operations@cgmerchants.com
          </a>
        </div>
      </div>
    </div>
  );
});

Contact.displayName = "Contact";

export default Contact;
