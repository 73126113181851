import { createRef } from "react";
import Navbar from "./components/Navbar/Navbar";
import Carousel from "./components/Carousel/Carousel";
import WhatWeDo from "./components/WhatWeDo/WhatWeDo";
import Products from "./components/Products/Products";
import Team from "./components/Team/Team";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";

const App = () => {
  const productsRef = createRef();
  const whatRef = createRef();
  const teamRef = createRef();
  const contactRef = createRef();

  const handleClick = (e, item) => {
    if (item === "#products")
      return productsRef.current?.scrollIntoView({ behavior: "smooth" });
    if (item === "#about")
      return whatRef.current?.scrollIntoView({ behavior: "smooth" });
    if (item === "#contact")
      return contactRef.current?.scrollIntoView({ behavior: "smooth" });
    if (item === "#team")
      return teamRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="bg-white">
      <Navbar onClick={handleClick} />

      <main className="mt-24">
        <Carousel />
        <WhatWeDo ref={whatRef} />
        <Products ref={productsRef} />
        <Team ref={teamRef} />
        <Contact ref={contactRef} />
      </main>

      <Footer />
    </div>
  );
};

export default App;
