import React, { useState } from "react";
import Plantation from "../../assets/plantation.svg";
import Wheat from "../../assets/wheat.svg";
import Forest from "../../assets/forest.svg";
import Plant from "../../assets/plant.svg";
import "./Carousel.css";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";

const images = [Plantation, Wheat, Forest, Plant];

export default function Carousel() {
  const [currentImage, setCurrentImage] = useState(0);
  const refs = images.reduce((acc, val, i) => {
    acc[i] = React.createRef();
    return acc;
  }, {});

  const scrollToImage = (i) => {
    setCurrentImage(i);
    refs[i].current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  const totalImages = images.length;

  const nextImage = () => {
    if (currentImage >= totalImages - 1) {
      scrollToImage(0);
    } else {
      scrollToImage(currentImage + 1);
    }
  };

  const previousImage = () => {
    if (currentImage === 0) {
      scrollToImage(totalImages - 1);
    } else {
      scrollToImage(currentImage - 1);
    }
  };

  const arrowStyle =
    "absolute text-white text-2xl z-10 h-10 w-10 rounded-full flex items-center justify-center";

  const sliderControl = (isLeft) => (
    <button
      type="button"
      onClick={isLeft ? previousImage : nextImage}
      className={`${arrowStyle} ${isLeft ? "left-2" : "right-2"}`}
      style={{ top: "40%" }}
    >
      <span aria-label={`Arrow ${isLeft ? "left" : "right"}`}>
        {isLeft ? (
          <ChevronLeftIcon className="h-10 w-10" />
        ) : (
          <ChevronRightIcon className="h-10 w-10" />
        )}
      </span>
    </button>
  );

  return (
    <div className="relative w-full lg:h-700 overflow-hidden">
      <div className="hidden lg:block">
        <div className="carousel">
          {sliderControl(true)}
          {images.map((img, i) => (
            <div className="w-full flex-shrink-0" key={img} ref={refs[i]}>
              <img src={img} className="w-full object-contain" />
            </div>
          ))}
          {sliderControl()}
          <div className="carousel-label absolute left-0 bottom-0 lg:left-20 lg:bottom-10 text-white">
            <h1 className="lg:text-100">
              Expressing future <br />
              trends,{" "}
              <span className="text-ceibos">
                <strong>
                  <i>today</i>{" "}
                </strong>
              </span>
              ”
            </h1>
            <button className="bg-ceibos px-8 py-4 font-bold text-white text-xl mt-20">
              CEIBOS GROUP
            </button>
          </div>
        </div>
      </div>
      <div
        className="lg:hidden w-full flex-shrink-0 relative"
        style={{
          backgroundImage: `url(${Plantation})`,
          backgroundSize: "cover",
          height: "500px",
        }}
      >
        <div className="flex flex-col items-center h-full justify-center">
          <h1 className="text-5xl lg:text-100 text-center text-white font-bold drop-the-shadow">
            ”Expressing future trends,{" "}
            <span className="text-ceibos">
              <strong>
                <i>today</i>{" "}
              </strong>
            </span>
            ”
          </h1>
          <button className="bg-ceibos px-4 py-2 font-bold text-white text-sm mt-5">
            CEIBOS GROUP
          </button>
        </div>
      </div>
    </div>
  );
}
