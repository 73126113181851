import React from "react";
import World from "../../assets/world.svg";

const WhatWeDo = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} id="about" className="relative pt-20 bg-ceibos-gray h-700">
      <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
        {/* Content area */}
        <div className="pt-12 sm:pt-16 lg:pt-10">
          <img src={World} className="m-auto pb-6" />
          <h2 className="uppercase text-3xl text-white text-center font-extrabold tracking-tight sm:text-4xl">
            What we do
          </h2>
          <div className="mt-6 text-gray-500 space-y-6">
            <p className="lg:text-2xl text-white text-center">
              CGM trades different ingredients, food and feed products,
              including grains, pulses, dairy, organics and non-gmo. We connect
              farmers and producers with buyers across the globe through an
              efficient logistics and financial platform, focused on
              establishing long term relationships based on quality of products
              and processes, generating mutual trust.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});

WhatWeDo.displayName = "WhatWeDo";

export default WhatWeDo;
