export default function ProductItem({ title, description, image }) {
  return (
    <div className="col-span-3 md:col-span-1">
      <div className="relative">
        <img src={image} alt={title} />
        <p className="absolute bottom-2 text-xl text-white font-bold w-full text-center">
          {title}
        </p>
      </div>
      <p className="text-gray-700 text-sm px-6 py-4 text-center">
        {description}
      </p>
    </div>
  );
}
