import React from "react";
import rowCrops from "../../assets/row-crops.svg";
import nonGmo from "../../assets/non-gmo.svg";
import forestry from "../../assets/forestry.svg";
import pulses from "../../assets/pulses.svg";
import pastures from "../../assets/pastures.svg";
import dairy from "../../assets/dairy.svg";
import ProductItem from "./ProductItem";

const items = [
  {
    id: 1,
    title: "ROW CROPS",
    description:
      "CORN | SOYBEANS | WHEAT | SORGHUM | SUNFLOWER | CANOLA | BARLEY",
    image: rowCrops,
  },
  {
    id: 2,
    title: "NON GMO & ORGANIC",
    description: "SOYBEANS | CORN | WHEAT",
    image: nonGmo,
  },
  { id: 3, title: "FORESTRY & WOOD", description: "", image: forestry },
  {
    id: 4,
    title: "PULSES",
    description:
      "ALUBIA BEANS | COLORED BEANS | MUNG BEANS | YELLOW & GREEN PEAS | CHICKPEAS | POP CORN | SESAME | CHIA",
    image: pulses,
  },
  { id: 5, title: "PASTURES & BEEF", description: "", image: pastures },
  {
    id: 6,
    title: "DAIRY",
    description: "MILK POWDER | AMF | DULCE DE LECHE | BUTTER",
    image: dairy,
  },
];

const Products = React.forwardRef((props, ref) => (
  <div ref={ref} id="product" className="max-w-7xl mx-auto pt-20">
    <h1 className="text-ceibos text-46 uppercase mb-10">
      <strong>Product</strong> Portfolio
    </h1>
    <div className="grid grid-cols-3 gap-4">
      {items.map((item) => (
        <ProductItem
          key={item.id}
          title={item.title}
          description={item.description}
          image={item.image}
        />
      ))}
    </div>
  </div>
));

Products.displayName = "Products";

export default Products;
